import * as React from "react"
import Layout from '../components/Layout'
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Helmet from 'react-helmet';
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image"

import "/_custom.scss"

import * as classes from "../components/Site.module.scss"

import LogoGithub from "../images/icon_github.svg";
import LogoLinkedin from "../images/icon_linkedin.svg";
import LogoInstagram from "../images/icon_insta.svg";
import LogoTwitter from "../images/icon_twitter.svg";
import Favicon from "../images/favicon.ico";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Kevin Blank - kevinblank.com</title>
        <link rel="icon" type="image/x-icon" href={Favicon}></link>
      </Helmet>
      <main>
        <div className={classes.heroDiv}>
        <Container>
          <Row style={{minHeight:"600px"}}>
            <Col xs={12}>
              <div style={centerCenter}>
              <div style={{fontWeight:"100",fontSize:"22px"}}>
                Mutli Passionate
              </div>
              <div style={{fontWeight:"100",fontSize:"22px"}}>
                Jack of all Trades
              </div>
              <div style={{fontWeight:"600",fontSize:"22px"}}>
                Full-stack Developer
              </div>
              <div style={{marginTop:"30px"}}>
              <Link to="/portfolio" className="btn btn-primary">See some of the things I've made</Link>
              </div>
              </div>
            </Col>
            <Col>           
            </Col>
          </Row>
        </Container>
        </div>
        <div style={{backgroundColor:"#f5f5f5"}}>
        <Container>
          <Row>
            <Col style={bigSpacing} xs={12} sm={6} md={4}>
              <div className={classes.footerSoftwareBackground}>
                <h5>About me</h5>
                <Link to="/about">Development ideas, hobbies, etc.</Link>
              </div>
            </Col>
            <Col style={bigSpacing} xs={12} sm={6} md={4}>
              <div className={classes.footerWorkBackground}>
                <h5>Where am I working?</h5>
                <Link to="/work" style={{zIndex:"100"}}>Learn more about my current career</Link>
              </div>
            </Col>
            <Col style={bigSpacing} xs={12} sm={6} md={4}>
                <div style={contentDiv}>
                    <img src={LogoGithub} style={logoStyle} /> <a href="https://github.com/zagnut007">@zagnut007</a>
                </div>
                <div style={contentDiv}>
                    <img src={LogoLinkedin} style={logoStyle} /> <a href="https://www.linkedin.com/in/kevin-blank-5901301b/">View Profile</a>
                </div>
                <div style={contentDiv}>
                    <img src={LogoInstagram} style={logoStyle} /> <a href="https://www.instagram.com/kevinblankmaker/">@kevinblankmaker</a>
                </div>
                <div style={contentDiv}>
                    <img src={LogoTwitter} style={logoStyle} /> <a href="https://twitter.com/kevinblank">@kevinblank</a>
                </div>
            </Col>
          </Row>
        </Container>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage

// styles
const bigSpacing = {
  padding: "40px 80px",
  position: "relative"
}

const softwareBackground = {
  height: "200px"
}

const logoStyle = {
  width: "20px",
  marginRight: "10px"
}

const contentDiv = {
  marginTop:"10px"
}

const centerCenter = {
  position: "relative",
  left:" 50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  marginLeft: "6vw"
}